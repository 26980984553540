import {Entity} from '@backstage/catalog-model'

export const DTRACK_URL_ANNOTATION = 'dtrack/url'
export const DTRACK_PROJECT_ID_PROD = 'dtrack/project-id-prod'
export const DTRACK_PROJECT_ID_STAGE = 'dtrack/project-id-stage'

export const getDtrackProjectId = (entity: Entity, env: string) => {
    if (env == "production") {
        return entity?.metadata.annotations?.[DTRACK_PROJECT_ID_PROD] ?? '';
    } else {
        return entity?.metadata.annotations?.[DTRACK_PROJECT_ID_STAGE] ?? '';
    }
}