import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    identityApiRef,
} from '@backstage/core-plugin-api';

import {rootRouteRef} from './routes';
import {dtrackApiRef, ProductionDtrackApi} from "./implement";

export const backstagePluginDtrackPlugin = createPlugin({
    id: 'backstage-plugin-dtrack',
    routes: {
        root: rootRouteRef,
    },
});

export const BackstagePluginDtrackPage = backstagePluginDtrackPlugin.provide(
    createRoutableExtension({
        name: 'BackstagePluginDtrackPage',
        component: () =>
            import('./components/DtrackIframe').then(m => m.DtrackIframe),
        mountPoint: rootRouteRef,
    }),
);

export const dtrackPlugin = createPlugin({
    id: 'dtrack',
    apis: [
        createApiFactory({
            api: dtrackApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                identityApi: identityApiRef
            },
            factory: ({
                          discoveryApi, identityApi
                      }) =>
                new ProductionDtrackApi(
                    discoveryApi, identityApi,
                ),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});