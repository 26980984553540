import {
    ClockConfig,
    HeaderWorldClock,
    HomePageRecentlyVisited,
    HomePageStarredEntities,
    HomePageToolkit,
    HomePageTopVisited,
    WelcomeTitle,
} from '@backstage/plugin-home';
import {Content, Header, Page} from '@backstage/core-components';
import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {HomePageSearchBar} from "@backstage/plugin-search";

const useStyles = makeStyles(theme => ({
    searchBar: {
        display: 'flex',
        maxWidth: '50vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        padding: '8px',
        borderRadius: '50px',
        margin: '25px auto',
    },
    searchBarOutline: {
        borderStyle: 'none',
    },
}));

const languages = ['English']
const clockConfigs: ClockConfig[] = [
    {
        label: 'BXL',
        timeZone: 'Europe/Brussels',
    },
];
const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
};
const arrayLink = [
    {
        label: "Azure Devops Rossel",
        url: "https://dev.azure.com/grouperossel/",
        icon: <img src={require("../../assets/icons/azure-devops.svg")} alt="azure-devops" height="58px"/>
    },
    {
        label: "Windows App Rossel",
        url: "https://windows365.microsoft.com/ent#/devices",
        icon: <img src={require("../../assets/icons/windows-app.png")} alt="windows-app" height="58px"/>
    },
    {
        label: "ELK Rossel",
        url: "https://celkross01.srv.rossel.cloud:5601/app/home#/",
        icon: <img src={require("../../assets/icons/elk.png")} alt="sharepoint" height="58px"/>
    },
    {
        label: "Grafana Rossel",
        url: "https://172.28.220.16:3000/dashboards",
        icon: <img src={require("../../assets/icons/grafana.png")} alt="sharepoint" height="58px"/>
    },
    {
        label: "SharePoint Rossel",
        url: "https://rossel.sharepoint.com/_layouts/15/sharepoint.aspx",
        icon: <img src={require("../../assets/icons/sharepoint.svg")} alt="sharepoint" height="58px"/>
    },
    {
        label: "Bookstack",
        url: "https://bookstack.contraste-digital.dev/",
        icon: <img src={require("../../assets/icons/bookstack.png")} alt="gitlab" height="58px"/>
    },
    {
        label: "Azure Portal",
        url: "https://portal.azure.com/#home",
        icon: <img src={require("../../assets/icons/azure-portal.png")} alt="azure-portal" height="58px"/>
    },
    {
        label: "SharePoint Contraste",
        url: "https://contrasteeurope.sharepoint.com/_layouts/15/sharepoint.aspx",
        icon: <img src={require("../../assets/icons/sharepoint.svg")} alt="sharepoint" height="58px"/>
    },
    {
        label: "Azure Devops TribuPresse",
        url: "https://dev.azure.com/TribuPresse/",
        icon: <img src={require("../../assets/icons/azure-devops.svg")} alt="azure-devops" height="58px"/>
    },
    {
        label: "Gitlab",
        url: "https://gitlab.audaxis.com/",
        icon: <img src={require("../../assets/icons/gitlab.svg")} alt="gitlab" height="58px"/>
    },
    {
        label: "Redmine Rossel",
        url: "http://rossel.audaxis.com/redmine/",
        icon: <img src={require("../../assets/icons/redmine.png")} alt="redmine" height="58px"/>
    },
    {
        label: "Redmine Audaxis",
        url: "https://helpdesk.audaxis.com/",
        icon: <img src={require("../../assets/icons/redmine.png")} alt="redmine" height="58px"/>
    },
]

export const HomePage = () => {
    const classes = useStyles();
    return (
        <Page themeId="home">
            <Header title={<WelcomeTitle language={languages}/>} pageTitleOverride="Home">
                <HeaderWorldClock
                    clockConfigs={clockConfigs}
                    customTimeFormat={timeFormat}
                />
            </Header>
            <Content>
                <Grid container justifyContent="center" spacing={6}>
                    <Grid container item xs={12} alignItems="center" direction="row">
                        <HomePageSearchBar
                            classes={{root: classes.searchBar}}
                            InputProps={{classes: {notchedOutline: classes.searchBarOutline}}}
                            placeholder="Search"
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <HomePageStarredEntities/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <HomePageToolkit
                            title="Lien utiles"
                            tools={arrayLink}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <HomePageTopVisited/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <HomePageRecentlyVisited/>
                    </Grid>
                </Grid>
            </Content>
        </Page>
    );
};