import {useEntity} from '@backstage/plugin-catalog-react';
import React from 'react';
import {dtrackPlugin} from './plugin';
import {rootRouteRef} from './routes';
import {createComponentExtension, createRoutableExtension,} from '@backstage/core-plugin-api';
import {Options} from '@material-table/core';

type DtrackPageProps = {
    tableOptions?: Options<never>;
}

export const EntityDtrackSummaryCard = dtrackPlugin.provide(
    createComponentExtension({
        name: 'EntityDtrackSummaryCard',
        component: {
            lazy: () =>
                import('./components/DtrackCard').then(
                    ({ DtrackSummaryCard }) => {
                        return ({
                                    tableOptions,
                                }: DtrackPageProps) => {
                            const {entity} = useEntity();
                            return (
                                <DtrackSummaryCard
                                    entity={entity}
                                    tableOptions={
                                        tableOptions || {
                                            padding: 'dense',
                                            paging: true,
                                            search: false,
                                            pageSize: 10,
                                        }
                                    }
                                />
                            );
                        };
                    },
                ),
        },
    }),
);

export const EntityDtrackFindingCard = dtrackPlugin.provide(
    createComponentExtension({
        name: 'EntityDtrackFindingCard',
        component: {
            lazy: () =>
                import('./components/DtrackCard').then(
                    ({ DtrackFindingCard }) => {
                        return ({
                                    tableOptions,
                                }: DtrackPageProps) => {
                            const {entity} = useEntity();
                            return (
                                <DtrackFindingCard
                                    entity={entity}
                                    tableOptions={
                                        tableOptions || {
                                            padding: 'dense',
                                            paging: true,
                                            search: false,
                                            pageSize: 10,
                                        }
                                    }
                                />
                            );
                        };
                    },
                ),
        },
    }),
);

export const EntityDtrackContent = dtrackPlugin.provide(
    createRoutableExtension({
        name: 'EntityDtrackContent',
        mountPoint: rootRouteRef,
        component: () =>
            import('./components/DtrackCard').then(
                ({ DtrackFindingCard }) => {
                    return (props: DtrackPageProps) => {
                        const {entity} = useEntity();
                        const defaultOptions: Options<never> = {
                            padding: 'dense',
                            paging: true,
                            search: false,
                            pageSize: 5,
                        };
                        return (
                            <DtrackFindingCard
                                entity={entity}
                                tableOptions={{...defaultOptions, ...props.tableOptions}}
                            />
                        );
                    };
                },
            ),
    })
);

export const EntityDtrackGraphCard = dtrackPlugin.provide(
    createComponentExtension({
        name: 'EntityDtrackGraphCard',
        component: {
            lazy: () =>
                import('./components/DtrackCard').then(
                    ({ DtrackGraphCard }) => {
                        return ({}) => {
                            const {entity} = useEntity();
                            return (
                                <DtrackGraphCard
                                    entity={entity}
                                />
                            );
                        };
                    },
                ),
        },
    }),
);